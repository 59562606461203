::selection {
  background: #774AD3B8;
}

@keyframes logo-slide-in {
  0% {
    width: 0
  }
  100% {
    width: 450px
  }
}